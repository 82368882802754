/* You can add global styles to this file, and also import other style files */
html {
  overflow-x:hidden;
  overflow-y:hidden; /* Needs to be "hidden" when live*/
  font-family: 'proxima-nova';
  color: rgba(106, 106, 106, 0.731);
} 

.spinner-container {
  position: relative;
  min-height: 250px;
}

.container {
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: row; */
  min-width: 50%;
  /* width: calc((25em - 100%) * 1000); */
  max-width: 100%;
}
